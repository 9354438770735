




































































































import Vue from 'vue';
import Property from '@/blueprint/components/landlord/Property.vue';

import { ActionPayload } from '@/interfaces';
import { Property as IProperty } from '@lordly/models2/interfaces/models/Property';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { LandlordState, IndexedProperty  } from '@/store/landlord/state';

export default Vue.extend({
  name: 'Portfolio-List-Page',
  components: { Property },
  inject: [],
  mixins: [],
  props: {},
  data () {
    return {
      showFilter: false,
    };
  },
  computed: {
    portfolio: {
      get (): IndexedProperty[] {
        return (this.$store.state.landlord as LandlordState).portfolio.display;
      },
    },
    portfolioCurrentPage: {
      get (): number {
        return (this.$store.state.landlord as LandlordState).portfolio.currentPage;
      },
      set (value: number): void {
        this.$store.commit('landlord/MutatePortfolioSetPage', value);
      },
    },
    portfolioTotalPages: {
      get (): number {
        return (this.$store.state.landlord as LandlordState).portfolio.totalPages;
      },
    },
    portfolioFilter: {
      get (): string | null {
        return (this.$store.state.landlord as LandlordState).portfolio.filter;
      },
      set (value: string) {
        this.$store.commit('landlord/MutatePortfolioFilter', value);
      },
    },
    portfolioLoading: {
      get (): boolean {
        return (this.$store.state.landlord as LandlordState).portfolio.loading;
      },
    },
  },
  // Methods
  methods: {
    async getPortfolio () {
      await this.$store.dispatch('landlord/actionGetPortfolio', { component: this } as ActionPayload);
    },
    invokeAddProperty () {
      // Clear previous form
      this.$store.commit('landlord/MutateResetForm');
      this.$router.push('/landlord/portfolio/add');
    },
    toggleSearchProperty () {
      this.showFilter = !this.showFilter;
    },
  },
});
